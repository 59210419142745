<template>
  <li :class="{ active: isActive }" class="phone-sidebar-item nav-item d-flex flex-column align-items-center justify-content-center " @click="nav">
    <fa-icon :icon="icon" size="2x" />
    <a class="nav-link">{{ text }}</a>
  </li>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'VirtualPhoneSidebarItem',
  props: {
    text: { type: String, default: '' },
    icon: { type: String, default: '' },
    route: { type: String, default: '#' },
  },
  computed: {
    isActive() {
      return this.$route.path.includes(this.route)
    },
  },
  methods: {
    ...mapGetters('virtualPhones', ['suspendedPhones']),
    nav() {
      if (this.suspendedPhones()?.length > 0) {
        return false
      }
      this.$router.push({ name: this.route })
    },
  },
}
</script>

<style lang="scss">
  .phone-sidebar-item {
    .nav-link {
      user-select: none;
    }
  }
</style>

