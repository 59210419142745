<template>
  <div class="position-relative">
    <div class="virtual-phone-sidenav">
      <ul class="nav flex-column">
        <virtual-phone-sidebar-item text="Phones" route="phones" icon="phone-alt" />
        <virtual-phone-sidebar-item text="Voicemail" route="voicemails" icon="voicemail" />
        <virtual-phone-sidebar-item text="Settings" route="settings" icon="cog" />
        <virtual-phone-sidebar-item text="Contacts" route="contacts" icon="address-book" />
      </ul>
    </div>
    <div class="virtual-phone-sidenav-spacer" />
  </div>
</template>

<script>
import VirtualPhoneSidebarItem from '@/components/VirtualPhones/VirtualPhoneSidebarItem.vue'
export default {
  name: 'VirtualPhoneSidebar',
  components: { VirtualPhoneSidebarItem },
}
</script>

<style lang="scss">

.virtual-phone-sidenav-spacer {
  width: 140px;
  position: relative;
}

.virtual-phone-sidenav {
  min-height: calc(100vh - 60px);
  width: 140px;
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #F6F6F6;
  overflow-x: hidden; /* Disable horizontal scroll */

  .svg-inline--fa {
    color: #1F2084
  }

  li {
    height: 140px;
    vertical-align: middle;
    cursor: pointer;
  }

  li:hover {
    background-color: #F0F0F0;
  }

  li.active {
    background-color: #EDECF6;
  }

  a {
    padding-top: 18px;
    color: #1F2084;
  }
}
/* On smaller screens, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px),
screen and (max-width: 1300px) {
  .virtual-phone-sidenav {
    width: 90px;
    li {
      height: 90px !important;
    }
    svg {
      font-size: 1.7em;
    }
    a {
      font-size: 12px;
      padding: 10px 0 0 0;
    }
  }
  .virtual-phone-sidenav-spacer {
    width: 90px;
  }
}

</style>
