var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass:
        "phone-sidebar-item nav-item d-flex flex-column align-items-center justify-content-center",
      class: { active: _vm.isActive },
      on: { click: _vm.nav },
    },
    [
      _c("fa-icon", { attrs: { icon: _vm.icon, size: "2x" } }),
      _c("a", { staticClass: "nav-link" }, [_vm._v(_vm._s(_vm.text))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }