var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "position-relative" }, [
    _c("div", { staticClass: "virtual-phone-sidenav" }, [
      _c(
        "ul",
        { staticClass: "nav flex-column" },
        [
          _c("virtual-phone-sidebar-item", {
            attrs: { text: "Phones", route: "phones", icon: "phone-alt" },
          }),
          _c("virtual-phone-sidebar-item", {
            attrs: {
              text: "Voicemail",
              route: "voicemails",
              icon: "voicemail",
            },
          }),
          _c("virtual-phone-sidebar-item", {
            attrs: { text: "Settings", route: "settings", icon: "cog" },
          }),
          _c("virtual-phone-sidebar-item", {
            attrs: {
              text: "Contacts",
              route: "contacts",
              icon: "address-book",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "virtual-phone-sidenav-spacer" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }